<template>
  <v-card
    outlined
    height="100%"
    class="d-flex flex-column"
  >
    <v-card-title class="my-4 title">
      <h4 class="px-4 pb-4 keep-word-break">
        {{ $t('support.support-form-card.title') }}
      </h4>
    </v-card-title>
    <v-card-text class="px-2">
      <feedback-form
        :classification-list="feedbackTicket.classifications"
        @feddbackSubmitted="handleFormSubmit"
        @formValuesChange="handleFormChange"
      />
    </v-card-text>
    <v-row class="mb-1 mr-2">
      <v-col class="align-right">
        <v-btn
          :disabled="resetButtonDisabled"
          class="mr-1"
          text
          @click="handleCancel"
        >
          {{ $t('labels.reset') }}
        </v-btn>
        <v-btn
          x-large
          color="primary"
          @click="submitFeedbackForm"
        >
          {{ $t('labels.submit') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import featureNames from '@/lib/featureNames'
import bus, { eventNames } from '@/lib/eventBus'
import FeedbackForm from '@/modules/feedback/FeedbackForm.vue'

export default {
  components: {
    FeedbackForm
  },
  data () {
    return {
      resetButtonDisabled: true
    }
  },
  computed: {
    feedbackTicket () {
      return this.$features.feature(featureNames.FEEDBACK_TICKET).config
    }
  },
  methods: {
    handleFormSubmit () {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.support.success') })
      this.handleCancel()
    },

    handleCancel () {
      bus.$emit(eventNames.RESET_FEEDBACK_FORM)
    },

    submitFeedbackForm () {
      bus.$emit(eventNames.SUBMIT_FEEDBACK_FORM)
    },

    handleFormChange ({ state }) {
      this.resetButtonDisabled = state === 'idle'
    }
  }
}
</script>
<style scoped>
.align-right {
  text-align: right;
}
.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.keep-word-break {
  word-break: keep-all;
  text-align: center;
}
</style>
