<template>
  <v-card
    v-if="company"
    outlined
    class="d-flex flex-column"
    width="100%"
  >
    <div class="support-bg">
      <div class="mt-12 px-4 pb-4 d-flex justify-center">
        <v-avatar
          v-for="(image, index) in images"
          :key="index"
          size="48"
          :style="{'z-index':images.length - index, 'margin-left':`${ index === 0 ? 0 : -25 }px`}"
        >
          <img
            width="50px"
            :src="image"
            :alt="index"
          >
        </v-avatar>
      </div>
    </div>

    <v-card-title class="justify-center d-flex my-md-4">
      <h4>
        {{ $t('support.support-card.title') }}
      </h4>
    </v-card-title>

    <v-card-text class="px-6 text-body-1">
      {{ $t('support.support-card.subtitle') }}:
    </v-card-text>
    <div class="my-4 px-4 pb-4 d-flex">
      <v-icon class="mr-4">
        mdi-email
      </v-icon>
      <div class="justify-center d-flex flex-column">
        <a :href="`mailto:${email}`">
          {{ email }}
        </a>
        {{ $t('labels.email') }}
      </div>
    </div>
  </v-card>
</template>

<script>
import featureNames from '@/lib/featureNames'

const csImages = [
  'img/customerSuccess/yannick.jpg',
  'img/customerSuccess/svenja.jpg',
  'img/customerSuccess/fabienne.png',
  'img/customerSuccess/andre.png',
  'img/customerSuccess/marcel.webp'
]

export default {
  props: {
    company: {
      type: Object,
      default: null
    },
    feedbackOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    images () {
      return csImages
    },
    defaultContact () {
      return this.$features.feature(featureNames.SUPPORT_CONTACT).config
    },
    phone () {
      const { country } = this.company
      return this.defaultContact.phone[country]
    },

    email () {
      return this.defaultContact.support.email
    }
  }
}
</script>

<style scoped>

.support-bg {
  background-color: #e9f4fe;
  height: 150px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>
